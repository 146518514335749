<template>
	<div>
		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12 p-3 g-0 text-start">
					<Publisher v-if="appState == 'publisher'" />
					<div v-if="appState == 'inspiration'">
						<div class="container text-start">
							<div class="row">
								<div class="col-md-12 mx-5 px-5">
									<small class="ms-2"><strong>{{ currentQuote.title }}</strong> {{ currentQuote.author }}</small><br/><br/>
									<p style="font-size: 120px; line-height: 70px; font-weight: bold;">{{ currentQuote.quote }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import axios from 'axios'
import Publisher from '@/components/dashboard/WorkspaceRentals.vue'

export default {
	name: 'Dashboard',
	components: {
    	Publisher
  	},
	mounted () {
		this.get_inspiration()
	},
	data() {
		return {
			appState: 'publisher',
			currentQuote: {
				quote: '',
				author: '',
				title: '',
			},
			quotes: [
				{
					"quote": "Believe you can and you're halfway there.",
					"author": "Theodore Roosevelt",
					"title": "Inspirational Quotes"
				},
				{
					"quote": "The only way to do great work is to love what you do.",
					"author": "Steve Jobs",
					"title": "Stay Hungry, Stay Foolish"
				},
				{
					"quote": "Success is not final, failure is not fatal: It is the courage to continue that counts.",
					"author": "Winston Churchill",
					"title": "Never Give In!"
				},
				{
					"quote": "The only limit to our realization of tomorrow will be our doubts of today.",
					"author": "Franklin D. Roosevelt",
					"title": "New Beginnings"
				},
				{
					"quote": "Your time is limited, don't waste it living someone else's life.",
					"author": "Steve Jobs",
					"title": "Stay Hungry, Stay Foolish"
				},
				{
					"quote": "The future belongs to those who believe in the beauty of their dreams.",
					"author": "Eleanor Roosevelt",
					"title": "Dream Big"
				},
				{
					"quote": "Don't watch the clock; do what it does. Keep going.",
					"author": "Sam Levenson",
					"title": "Time Management Wisdom"
				},
				{
					"quote": "It's not whether you get knocked down, it's whether you get up.",
					"author": "Vince Lombardi",
					"title": "Perseverance Power"
				},
				{
					"quote": "Success is stumbling from failure to failure with no loss of enthusiasm.",
					"author": "Winston Churchill",
					"title": "Never Give In!"
				},
				{
					"quote": "The only place where success comes before work is in the dictionary.",
					"author": "Vidal Sassoon",
					"title": "Hard Work Pays Off"
				},
				{
					"quote": "Your attitude, not your aptitude, will determine your altitude.",
					"author": "Zig Ziglar",
					"title": "Positive Thinking"
				},
				{
					"quote": "In the middle of difficulty lies opportunity.",
					"author": "Albert Einstein",
					"title": "Wise Words"
				},
				{
					"quote": "The only way to achieve the impossible is to believe it is possible.",
					"author": "Charles Kingsleigh",
					"title": "Alice in Wonderland"
				},
				{
					"quote": "Success is not in what you have, but who you are.",
					"author": "Bo Bennett",
					"title": "Defining Success"
				},
				{
					"quote": "The only person you are destined to become is the person you decide to be.",
					"author": "Ralph Waldo Emerson",
					"title": "Self-Determination"
				},
				{
					"quote": "The journey of a thousand miles begins with one step.",
					"author": "Lao Tzu",
					"title": "Ancient Wisdom"
				},
				{
					"quote": "Don't be pushed around by the fears in your mind. Be led by the dreams in your heart.",
					"author": "Roy T. Bennett",
					"title": "The Light in the Heart"
				},
				{
					"quote": "Success is not just about making money. It's about making a difference.",
					"author": "Unknown",
					"title": "Making an Impact"
				},
				{
					"quote": "You are never too old to set another goal or to dream a new dream.",
					"author": "C.S. Lewis",
					"title": "Endless Possibilities"
				},
				{
					"quote": "The harder the conflict, the greater the triumph.",
					"author": "George Washington",
					"title": "Leadership Insights"
				}
			]
		}
	},
	methods: {
		test_hubspot: async function () {

			// const hubspot = require('@hubspot/api-client')
			// const hubspotClient = new hubspot.Client({ accessToken: 'pat-eu1-8470c8e7-e7ce-4496-9090-55c2ad5b2b55' })
			// const allContacts = await hubspotClient.crm.contacts.getAll()
			// console.log(allContacts)

			await axios
				.get('https://api.hubapi.com/crm/v3/objects/contacts',
				{
					headers: {
						'Accept': 'application/json',
						'Authorization': "Bearer 'pat-eu1-8470c8e7-e7ce-4496-9090-55c2ad5b2b55'"
					}
				})
				.then(response => (console.log(response.data)))
				.catch(error => (console.log(error)))
		},
		get_inspiration: async function () {
			const randomIndex = Math.floor(Math.random() * this.quotes.length)
			this.currentQuote = this.quotes[randomIndex]
		}
	}
}
</script>